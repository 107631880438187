import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  Button,
  Loader,
  Container,
  Card,
  Text,
  Alert,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { getAdminTasks, getClients, getUser } from "../../functions/api";
import "./AdminTasks.css";
import { TaskItem, TaskList } from "../../Components/TaskLine";
import AutoSelect from "../../Components/Inputs/AutoSelect";
import { AlertCircle } from "tabler-icons-react";

const FlexContainer = ({ children }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
      {children.map((child, kx) => (
        <div
          key={kx}
          style={{
            flex: "1 1 350px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

const ClearButton = ({ clear, nmt }) => {
  return (
    <Button
      variant="outline"
      style={!nmt ? { marginTop: 25 } : { marginTop: 0 }}
      onClick={clear}
    >
      Clear
    </Button>
  );
};

const AdminTasks = () => {
  const [status, setStatus] = useState("all");
  const [assignedTo, setAssignedTo] = useState("");
  const [completedDateFrom, setCompletedDateFrom] = useState("");
  const [completedDateTo, setCompletedDateTo] = useState("");
  const [deadlineFrom, setDeadlineFrom] = useState("");
  const [deadlineTo, setDeadlineTo] = useState("");
  const [clientSearch, setClientSearch] = useState("");
  const [assignedToSearch, setAssignedToSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const [dosearch, setDo] = useState(false);

  // useEffect(() => {
  //   handleSearch();
  // }, [
  //   status,
  //   assignedTo,
  //   completedDateFrom,
  //   completedDateTo,
  //   deadlineFrom,
  //   deadlineTo,
  //   clientSearch,
  //   assignedToSearch,
  // ]);

  const handleSearch = async () => {
    if (
      status == "all" &&
      assignedTo == "" &&
      completedDateFrom == "" &&
      completedDateTo == "" &&
      deadlineFrom == "" &&
      deadlineTo == "" &&
      clientSearch == "" &&
      assignedToSearch == ""
    ) {
      setDo(false);
      return;
    }
    setDo(true);
    setIsLoading(true);
    try {
      const response = await getAdminTasks(
        status,
        assignedTo,
        completedDateFrom,
        completedDateTo,
        deadlineFrom,
        deadlineTo,
        clientSearch,
        assignedToSearch
      );
      setTasks(response);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleClearStatus = () => {
    setStatus("all");
    //handleSearch();
  };

  const handleClearAssignedTo = () => {
    setAssignedTo("");
    //handleSearch();
  };

  const handleClearCompletedDateFrom = () => {
    setCompletedDateFrom("");
    //handleSearch();
  };

  const handleClearCompletedDateTo = () => {
    setCompletedDateTo("");
    //handleSearch();
  };

  const handleClearDeadlineFrom = () => {
    setDeadlineFrom("");
   // handleSearch();
  };

  const handleClearDeadlineTo = () => {
    setDeadlineTo("");
    //handleSearch();
  };

  const handleClearClientSearch = () => {
    setClientSearch("");
    //handleSearch();
  };

  const handleClearAssignedToSearch = () => {
    setAssignedToSearch("");
    //handleSearch();
  };

  const handleClearAll = () => {
    window.location.reload();
  };

  const renderTasks = () => {
    const displayMore = true;
    if(dosearch == false){
      return <div>Lūdzu izvēlaties kādus meklēšanas nosacījumus</div>
    }
    return (
      tasks &&
      tasks.length > 0 &&
      tasks?.map((task) => (
        <div key={task.taskid}>
          <TaskItem
            onClick={() => window.open("/partner/" + task.clientid, "_blank")}
            task={task}
            displayData={{
              showCheckbox: displayMore,
              showAsignedTo: displayMore,
              showCreatedBy: displayMore,
              showCreatedOn: displayMore,
              showDeadline: displayMore,
            }}
          />
        </div>
      ))
    );
  };

  return (
    <Container className="admin-tasks-container">
            <Alert icon={<AlertCircle size="2rem" />} title="Drīzumā!" color="green" style={{marginBottom: 15}}>
      Šeit būs iespēja filtrēt arī pēc izveidotajiem un aizvērtajiem uzdevumiem.
    </Alert>
      <div style={{ padding: 15 }}>
        <FlexContainer>
          <>
            {" "}
            <Select
              style={{ flex: "1 1 250px" }}
              label="Status"
              placeholder="Select status"
              data={[
                { value: "all", label: "All" },
                { value: "completed", label: "Completed" },
                { value: "not-completed", label: "Not completed" },
              ]}
              value={status}
              onChange={(event) => setStatus(event)}
            />
            {status !== "all" && <ClearButton clear={handleClearStatus} />}{" "}
          </>
          <>
            <div style={{ width: "100%" }}>
              <AutoSelect
                title="Assigned User"
                placeholder="search for a user"
                getData={getUser}
                callBack={setAssignedTo}
                customStyle={{
                  flex: "1 1 350px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: 5,
                  justifyContent: "space-between",
                }}
                customReset={
                  assignedTo !== "" &&
                  assignedTo !== null && (
                    <ClearButton nmt={true} clear={handleClearAssignedTo} />
                  )
                }
              />
            </div>
          </>
        </FlexContainer>
        <FlexContainer>
          <>
            <DateInput
              label="Completed date from"
              value={completedDateFrom}
              style={{ flex: 1 }}
              onChange={(date) => setCompletedDateFrom(date)}
            />
            {completedDateFrom !== "" && (
              <ClearButton clear={handleClearCompletedDateFrom} />
            )}
          </>
          <>
            <DateInput
              label="Completed date to"
              value={completedDateTo}
              style={{ flex: 1 }}
              onChange={(date) => setCompletedDateTo(date)}
            />
            {completedDateTo !== "" && (
              <ClearButton clear={handleClearCompletedDateTo} />
            )}
          </>
        </FlexContainer>
        <FlexContainer>
          <>
            <DateInput
              label="Deadline date from"
              value={deadlineFrom}
              style={{ flex: 1 }}
              onChange={(date) => setDeadlineFrom(date)}
            />
            {deadlineFrom !== "" && (
              <ClearButton clear={handleClearDeadlineFrom} />
            )}
          </>
          <>
            <DateInput
              label="Deadline date to"
              value={deadlineTo}
              style={{ flex: 1 }}
              onChange={(date) => setDeadlineTo(date)}
            />
            {deadlineTo !== "" && <ClearButton clear={handleClearDeadlineTo} />}
          </>
        </FlexContainer>
        <FlexContainer>
          <div style={{ width: "100%" }}>
            <AutoSelect
              title="Client"
              placeholder="search for a client"
              getData={(data) => getClients(data, true)}
              callBack={setClientSearch}
              customStyle={{
                flex: "1 1 350px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: 5,
                justifyContent: "space-between",
              }}
              customReset={
                clientSearch !== "" &&
                clientSearch !== null && (
                  <ClearButton nmt={true} clear={handleClearClientSearch} />
                )
              }
            />
          </div>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 15,
                padding: 8,
                backgroundColor: "#edededaa",
                border: "1px solid lightgrey",
                borderRadius: 5,
                width: "100%",
              }}
            >
              <div>
                <Button variant="outline" onClick={handleSearch}>
                  Search
                </Button>{" "}
                <Button variant="outline" onClick={handleClearAll}>
                  Clear All
                </Button>
              </div>
              <div>Results: {tasks ? tasks.length : 0}</div>
            </div>
          </>
        </FlexContainer>
      </div>
      <div style={{ padding: 15 }}>
        {isLoading ? (
          <Loader className="loader" />
        ) : (
          <div className="tasks-list">{renderTasks()}</div>
        )}
      </div>
    </Container>
  );
};

export default AdminTasks;
