import React from "react";
import {
  PhoneOutgoing,
  PhoneIncoming,
  PhoneX,
  PhoneCheck,
  PhoneOff,
  Phone,
  ArrowNarrowRight,
  ArrowNarrowLeft,
} from "tabler-icons-react";
import { Card } from "@mantine/core";

const CallCard = ({ callLog }) => {
  return (
    <>
      {callLog.map((cll) => {
        const date = new Date(cll.starttime);
        const year = date.getFullYear().toString().slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);

        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);

        const formattedTime = (
          <div>
            <div style={{ fontSize: 13 }}>
              {hours}:{minutes}:{seconds}
            </div>
            <span style={{ fontSize: 12 }}>
              {day}/{month}/{year}
            </span>
          </div>
        );

        return (
          <Card
            key={cll.call_id}
            withBorder
            style={{
              marginBottom: 2,
              backgroundColor:
                cll.status !== "answer" ? "#fc130325" : "inherit",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div style={{ display: "flex" }}>
                  {cll.direction === "outbound" && (
                    <PhoneOutgoing
                      size={18}
                      strokeWidth={2}
                      color={"green"}
                      style={{ marginRight: 5 }}
                    />
                  )}
                  {cll.direction === "inbound" && (
                    <PhoneIncoming
                      size={18}
                      strokeWidth={2}
                      color={"green"}
                      style={{ marginRight: 5 }}
                    />
                  )}

                  {cll.status === "cancel" && (
                    <PhoneX
                      size={18}
                      strokeWidth={2}
                      color={"red"}
                      style={{ marginRight: 5 }}
                    />
                  )}

                  {cll.status === "answer" && (
                    <PhoneCheck
                      size={18}
                      strokeWidth={2}
                      color={"green"}
                      style={{ marginRight: 5 }}
                    />
                  )}

                  {(cll.status === "chanunavail" || cll.status === "busy") && (
                    <PhoneOff
                      size={18}
                      strokeWidth={2}
                      color={"red"}
                      style={{ marginRight: 5 }}
                    />
                  )}
                </div>
                <span style={{ fontSize: 11 }}>{cll.duration}s</span>
              </div>
              <span style={{ fontSize: 12 }}>
                {cll.status === "answer" && "Answered"}
                {cll.status === "cancel" && "Cancelled"}
                {(cll.status === "chanunavail" || cll.status === "busy") &&
                  "Unavailable"}
              </span>
              {formattedTime}
            </div>
            <div
              style={{
                fontSize: 11,
                display: "flex",
                justifyContent: "space-around",
                paddingLeft: 40,
                paddingRight: 40,
              }}
            >
              <span style={{ display: "flex" }}>
                <Phone size={14} style={{ marginRight: 5 }} />{" "}
                {cll?.name}{" "}{cll?.surname}
                {/* {cll.caller_nr} */}
              </span>
              {cll.direction === "outbound" ? (
                <ArrowNarrowRight color={"green"} size={16} />
              ) : (
                <ArrowNarrowLeft color={"green"} size={16} />
              )}
              <span style={{ display: "flex" }}>
                <Phone size={14} style={{ marginRight: 5 }} /> {cll.callee_nr}
              </span>
            </div>
            {cll.status === "answer" && cll.audio_url && (
              <audio controls style={{ marginTop: 4, width: "100%" }}>
                <source src={cll.audio_url} type="audio/ogg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </Card>
        );
      })}
    </>
  );
};

export default CallCard;
