import React, { useEffect, useState } from "react";
import {
  Button,
  Slider,
  Checkbox,
  Tabs,
  Modal,
  Anchor,
  TextInput,
  Select,
  Group,
  Stepper,
  Card,
} from "@mantine/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Box } from "@chakra-ui/react";
import {
  getCredentials,
  getPhoneNumbers,
  getTaskCount,
  getUser,
  taskMigration,
  updateCredentials,
  updatePhoneNumber,
} from "../../functions/api";
import AutoSelect from "../../Components/Inputs/AutoSelect";
import { DateInput, DatePicker } from "@mantine/dates";
import { makeStyles } from "@material-ui/core/styles";
import AdminUsers from "./AdminUsers";
import { getReportFilters } from "../../functions/api";
import { ApiTab } from "./ApiScreen";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { TaskItem } from "../../Components/TaskLine";
import { timeDiffToDisplay } from "../../functions/misc";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  searchFilter: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
  },
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
  tableHeadCell: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    cursor: "pointer",
  },
  tableCell: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    cursor: "pointer",
  },
}));

const AdminScreen = () => {
  const [filters, setFilters] = useState();
  const [recheck, setRecheck] = useState();

  const navigate = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  return (
    <Box bg="gray.100" minH="100vh" p={8}>
      <Tabs
        keepMounted={false}
        color="teal"
        value={tab}
        onTabChange={(value) => navigate(`/admin/${value}`)}
      >
        <Tabs.List>
          <Tabs.Tab value="transfer-task">Pārcelt uzdevumu</Tabs.Tab>
          <Tabs.Tab value="number-management" color="blue">
            Numuru pārvaldība
          </Tabs.Tab>
          <Tabs.Tab value="user-management" color="blue">
            Lietotāju pārvaldība
          </Tabs.Tab>
          <Tabs.Tab value="credentials" color="blue">
            Rekvizītu pārvaldība
          </Tabs.Tab>
          <Tabs.Tab value="right-management" color="green" disabled>
            Tiesības
          </Tabs.Tab>
          <Tabs.Tab value="import" color="green" disabled>
            Import
          </Tabs.Tab>
          <Tabs.Tab value="api-management" color="green">
            API
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="transfer-task" pt="xs">
          <TaskTransferModel />
        </Tabs.Panel>

        <Tabs.Panel value="number-management" pt="xs">
          <AdminNumberScreen />
        </Tabs.Panel>
        <Tabs.Panel value="user-management" pt="xs">
          <AdminUsers />
        </Tabs.Panel>
        <Tabs.Panel value="credentials" pt="xs">
          <CredentialTab />
        </Tabs.Panel>
        <Tabs.Panel value="import" pt="xs">
          <ImportTab />
        </Tabs.Panel>
        {/* <Tabs.Panel value="right-management" pt="xs">
          <AdminRights />
        </Tabs.Panel> */}
        <Tabs.Panel value="api-management" pt="xs">
          <ApiTab filters={filters} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

const AdminNumberScreen = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filters, setFilters] = useState();
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  useEffect(() => {
    getPhoneNumbers().then((x) => setData(x));
  }, [opened, selected]);

  const handleNumberSave = () => {
    updatePhoneNumber(selected).then(() => closeModal());
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelected({ ...selected, [name]: value });
  };

  const closeModal = () => {
    close();
    setSelected(null);
  };

  return (
    <div className={classes.root}>
      <span style={{ fontSize: 12, paddingTop: 10, paddingBottom: 10 }}>
        NB: This is only for{" "}
        <Anchor href="https://teltel.io" target="_blank">
          teltel
        </Anchor>{" "}
        integration and numbers
      </span>
      <Modal
        opened={opened}
        onClose={closeModal}
        title="Add new number"
        centered
      >
        <span style={{ fontSize: 12 }}>
          NB: This is only for{" "}
          <Anchor href="https://teltel.io" target="_blank">
            teltel
          </Anchor>{" "}
          integration and numbers
        </span>
        <TextInput
          value={selected && selected.caller_number}
          label="Number"
          name="caller_number"
          onChange={handleInputChange}
          style={{ paddingTop: 20 }}
        />
        <TextInput
          value={selected && selected.api_key}
          label="API KEY"
          name="api_key"
          onChange={handleInputChange}
          style={{ paddingTop: 10 }}
        />
        <Select
          value={selected && selected.user_id}
          searchable
          onChange={(x) => setSelected({ ...selected, ["user_id"]: x })}
          label="User for this number"
          name="user_id"
          data={filters && filters.users}
          style={{ paddingTop: 10 }}
        />
        {/* <Select
          style={{ paddingTop: 10 }}
          label="status"
          data={[
            { value: "disabled", label: "Disabled" },
            { value: "active", label: "Active" },
          ]}
        /> */}
        <Button
          variant="contained"
          style={{
            backgroundColor: "dodgerblue",
            color: "white",
            marginTop: 30,
            marginBottom: 20,
            float: "right",
          }}
          color="primary"
          onClick={handleNumberSave}
        >
          Save
        </Button>
      </Modal>
      <div className={classes.searchFilter}>
        <Button
          variant="contained"
          style={{ backgroundColor: "dodgerblue", color: "white" }}
          color="primary"
          onClick={open}
        >
          Add number
        </Button>
      </div>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ maxWidth: 50, overflow: "hidden" }}
                className={classes.tableHeadCell}
              >
                Number
              </TableCell>
              <TableCell
                style={{ maxWidth: 50, overflow: "hidden" }}
                className={classes.tableHeadCell}
              >
                Assigned to
              </TableCell>
              <TableCell
                style={{ maxWidth: 20, overflow: "hidden" }}
                className={classes.tableHeadCell}
              >
                Status
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                API KEY (part of it)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((row) => (
                <TableRow
                  key={row.userid}
                  onClick={() => {
                    setSelected(row);
                    open();
                  }}
                >
                  <TableCell
                    style={{ maxWidth: 50, overflow: "hidden" }}
                    className={classes.tableCell}
                  >
                    {row.caller_number}
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 50, overflow: "hidden" }}
                    className={classes.tableCell}
                  >
                    {row.assigned_to}
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 20, overflow: "hidden" }}
                    className={classes.tableCell}
                  >
                    {row.status ? row.status : "active"}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ maxWidth: 200, overflow: "hidden" }}
                  >
                    {row.api_key}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const TaskTransferModel = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [todata, setTodata] = useState();
  const [fromdata, setFromdata] = useState();
  const [amount, setAmount] = useState();
  const [chb, setChb] = useState(false);
  const [deadline, setDeadline] = useState();

  const [filters, setFilters] = useState();

  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);

  useEffect(() => {
    to
      ? to.userid && getTaskCount(to.userid).then((data) => setTodata(data))
      : setTodata(null);
  }, [to]);

  useEffect(() => {
    console.log(from);
    from
      ? from.userid &&
        getTaskCount(from.userid).then((data) => {
          setFromdata(data);
          setAmount(data.incomplete_task_count);
        })
      : setFromdata(null);
  }, [from]);

  const callBackSetUser = (data, source) => {
    data != null ? 
    getUser(null, data).then((x) => {
      source == "from" ? setFrom(x[0]) : setTo(x[0]);
    }) : setFrom(null);
  };

  const moveTasks = () => {
    const migration = {
      from: from.userid,
      to: to.userid,
      amount: amount,
      chb: chb,
      deadline: deadline,
    };
    taskMigration(migration).then((data) => {
      data.success && window.alert("Uzdevumi pārnesti!");
      data.success && window.location.reload();
    });
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          minWidth: 350,
          padding: 10,
          marginTop: 0,
          marginBottom: "auto",
        }}
      >
        {/* <AutoSelect
          title="Izvēlies lietotāju no kura pārcelt uzdevumus"
          placeholder="Meklē lietotāju"
          getData={(data) => getUser(data, true)}
          callBack={console.log}
        /> */}
        <Select
          searchable
          data={filters ? filters?.users : []}
          label="Izvēlies lietotāju no kura pārcelt uzdevumus"
          placeholder="Meklē lietotāju"
          clearable
          value={from && parseInt(from?.userid)}
          onChange={(x) => callBackSetUser(x, "from")}
        />
        {fromdata && (
          <div style={{ padding: 10, backgroundColor: "#fafafa" }}>
            <div>uzdevumi: {fromdata?.total_task_count}</div>
            <div>nepabeigtie: {fromdata?.incomplete_task_count}</div>
            <div>
              ar nekavētu izpildes laiku:{" "}
              {fromdata?.upcoming_deadline_task_count}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          flex: 1,
          minWidth: 350,
          padding: 10,
          marginTop: 0,
          marginBottom: "auto",
        }}
      >
        {/* <AutoSelect
          title="Izvēlies lietotāju uz kuru pārcelt uzdevumus"
          placeholder="Meklē lietotāju"
          getData={(data) => getUser(data)}
          callBack={setTo}
        /> */}
        <Select
          searchable
          data={filters ? filters?.users : []}
          label="Izvēlies lietotāju uz kuru pārcelt uzdevumus"
          placeholder="Meklē lietotāju"
          clearable
          value={to && parseInt(to?.userid)}
          onChange={(x) => callBackSetUser(x, "to")}
        />
        {todata && (
          <div style={{ padding: 10, backgroundColor: "#fafafa" }}>
            <div>uzdevumi: {todata?.total_task_count}</div>
            <div>nepabeigtie: {todata?.incomplete_task_count}</div>
            <div>
              ar nekavētu izpildes laiku: {todata?.upcoming_deadline_task_count}
            </div>
          </div>
        )}
      </div>
      <div style={{ flex: 1, minWidth: 350, marginBottom: "auto" }}>
        {from && to && (
          <div>
            <Slider
              value={amount}
              onChange={(newValue) => setAmount(newValue)}
              defaultValue={fromdata?.incomplete_task_count}
              min={0}
              max={fromdata?.incomplete_task_count}
            />
            <div style={{ display: "flex" }}>
              <Checkbox checked={chb} onChange={() => setChb(!chb)} /> Uzstādīt
              jaunu izpildes termiņu?
            </div>
            {chb && (
              <DateInput
                value={deadline}
                onChange={setDeadline}
                label="Jaunais deadline"
              />
            )}
            <div style={{ marginTop: 15 }}>
              Pārvietot {amount} uzdevumus no {from?.name} {from?.surname} uz{" "}
              {to?.name} {to?.surname}?
              <button
                style={{
                  display: "block",
                  padding: "10px 30px",
                  backgroundColor: "dodgerblue",
                  color: "white",
                  borderRadius: 10,
                  marginTop: 10,
                }}
                onClick={moveTasks}
              >
                Pārvietot
              </button>
            </div>
          </div>
        )}
        {/* {fromdata && (
          <div>
            <h2 style={{ fontSize: 20, marginTop: 20 }}>
              {from?.name} {from?.surname} <small>({from?.email})</small>,
              Nepabeigtie uzdevumi:
            </h2>
            {fromdata?.task_rows.map((x) => {
              const deadlineDate = new Date(x.deadline);
              const isTaskPastDeadline = deadlineDate < new Date();
              const timeDiff = deadlineDate - new Date();

              return (
                <Card withBorder shadow="sm">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Checkbox />
                        <a
                          href={`/partner/${x.clientid}`}
                          target="_blank"
                          style={{
                            marginLeft: 20,
                            color: "dodgerblue",
                            textDecoration: "underline",
                          }}
                        >
                          {x.task_name}
                        </a>
                      </div>
                      <small>{x.task_description}</small>
                    </div>
                    <div>
                      <div style={{ textAlign: "right" }}>
                        <div style={isTaskPastDeadline ? { color: "red" } : {}}>
                          {timeDiffToDisplay(timeDiff)}{" "}
                          {isTaskPastDeadline ? " ago" : " left"}
                        </div>
                        <small>
                          {new Date(x.deadline).toLocaleString("default", {
                            dateStyle: "medium",
                            timeStyle: "short",
                            hour12: true,
                          })}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div>
                    <small style={{ color: "grey" }}>
                      {x.cb_username}, {x.cb_email},{" "}
                      {new Date(x.created).toLocaleString("default", {
                        dateStyle: "medium",
                        timeStyle: "short",
                        hour12: true,
                      })}
                    </small>
                  </div>
                </Card>
              );
            })}
          </div>
        )} */}
      </div>
    </>
  );
};

const CredentialTab = () => {
  const classes = useStyles();

  const [opened, { open, close }] = useDisclosure(false);
  const [selected, setSelected] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    getCredentials().then((a) => setData(a));
  }, [selected, opened]);

  const closeModal = () => {
    close();
    setSelected(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelected({ ...selected, [name]: value });
  };
  const handleCredentialSave = () => {
    updateCredentials(selected).then(() => closeModal());
  };

  return (
    <div>
      <Modal
        opened={opened}
        onClose={closeModal}
        title="Pievienot/labot rekvizītus"
        centered
      >
        {selected && selected.editable == "false" && (
          <div>
            <span style={{ fontSize: 12 }}>
              Ar konkrētajiem rekvizītiem jau ir izrakstīti rēķini - labot to
              vairs nav iespējams, bet var deaktivizēt, lai to nevarētu izmantot
              turpmākiem rēķiniem
            </span>
          </div>
        )}
        <Select
          style={{ paddingTop: 10 }}
          label="status"
          onChange={(x) => setSelected({ ...selected, ["status"]: x })}
          value={selected && selected.status}
          data={[
            { value: "DISABLED", label: "Deaktivizēts" },
            { value: "ACTIVE", label: "Aktīvs" },
          ]}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.company_name}
          label="Kompānijas nosaukums"
          name="copmany_name"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />
        {/* <TextInput
          value={selected && selected.comment}
          label="Iekšējais komentārs"
          name="comment"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />
        <TextInput
          value={selected && selected.public_comment}
          label="Publisks komentārs"
          name="public_comment"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        /> */}
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.bank_name}
          label="Bankas nosaukums"
          name="bank_name"
          onChange={handleInputChange}
          style={{ paddingTop: 10 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.bank_account}
          label="Bankas konts"
          name="bank_account"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.swift}
          label="Bankas SWIFT"
          name="swift"
          onChange={handleInputChange}
          style={{ paddingTop: 10 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.iban}
          label="IBAN"
          name="iban"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.reg_nr}
          label="Uzņēmuma reģistrācijas numurs"
          name="reg_nr"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.jur_address}
          label="juridiskā adrese"
          name="jur_address"
          onChange={handleInputChange}
          style={{ paddingTop: 10 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.fact_address}
          label="Faktiskā adrese"
          name="fact_address"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />
        <TextInput
          disabled={selected && selected.editable == "false"}
          value={selected && selected.vat}
          label="PVN"
          name="vat"
          onChange={handleInputChange}
          style={{ paddingTop: 5 }}
        />

        <Button
          variant="contained"
          style={{
            backgroundColor: "dodgerblue",
            color: "white",
            marginTop: 30,
            marginBottom: 20,
            float: "right",
          }}
          color="primary"
          onClick={handleCredentialSave}
        >
          Save
        </Button>
      </Modal>
      <div>
        <span style={{ fontSize: 14, paddingTop: 10, paddingBottom: 10 }}>
          Rekvizīti rēķiniem. Rekvizīti nevar tikt mainīti pēc pirmā rēķina
          izrakstīšanas!
        </span>
      </div>
      <Button
        variant="contained"
        style={{ marginTop: 10, backgroundColor: "dodgerblue", color: "white" }}
        color="primary"
        onClick={open}
      >
        Pievienot rekvizītus
      </Button>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ maxWidth: 150, overflow: "hidden" }}
                className={classes.tableHeadCell}
              >
                Nosaukums
              </TableCell>
              <TableCell
                style={{ width: "content-fit", overflow: "hidden" }}
                className={classes.tableHeadCell}
              >
                Reģistrācijas numurs
              </TableCell>
              <TableCell
                style={{ width: "content-fit", overflow: "hidden" }}
                className={classes.tableHeadCell}
              >
                Status
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Juridiskā adrese
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Faktiskā adrese
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                Bankas konts
              </TableCell>
              <TableCell className={classes.tableHeadCell}>PVN</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((row) => (
                <TableRow
                  key={row.cred_id}
                  onClick={() => {
                    setSelected(row);
                    open();
                  }}
                >
                  <TableCell
                    style={{ width: "content-fit", overflow: "hidden" }}
                    className={classes.tableCell}
                  >
                    {row.company_name}
                  </TableCell>
                  <TableCell
                    style={{ width: "content-fit", overflow: "hidden" }}
                    className={classes.tableCell}
                  >
                    {row.reg_nr}
                  </TableCell>
                  <TableCell
                    style={{ maxWidth: 50, overflow: "hidden" }}
                    className={classes.tableCell}
                  >
                    {row.status ? row.status : "active"}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ maxWidth: 200, overflow: "hidden" }}
                  >
                    {row.jur_address}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ maxWidth: 200, overflow: "hidden" }}
                  >
                    {row.fact_address}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ maxWidth: 200, overflow: "hidden" }}
                  >
                    {row.jur_address}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ maxWidth: 200, overflow: "hidden" }}
                  >
                    {row.vat}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const ImportTab = () => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [columnMappings, setColumnMappings] = useState({});
  const [ignoredColumns, setIgnoredColumns] = useState([]);

  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setData(null); // Clear previous data if any

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = handleFileRead;
      reader.readAsText(uploadedFile);
    }
  };

  const handleFileRead = (event) => {
    const content = event.target.result;
    const rows = content.split("\n").slice(0, 11); // Read the first 10 rows

    // Check if the first row is a header row
    const hasHeaderRow =
      rows.length > 1 &&
      rows[0].split(",").length === rows[1].split(",").length;

    // Assuming CSV has a header row, split it into columns
    const columns = rows[0].split(",");

    // Update the state with the data and default column mappings
    setData({ rows: hasHeaderRow ? rows.slice(1) : rows, columns });
    setColumnMappings(
      Object.fromEntries(columns.map((column) => [column, ""]))
    );
    setActive(1);
  };

  const handleColumnMappingChange = (event, columnName) => {
    const mappedColumnName = event.target.value;
    setColumnMappings((prevMappings) => ({
      ...prevMappings,
      [columnName]: mappedColumnName,
    }));

    // Select "Ignore" option automatically if no mapping is selected
    if (!mappedColumnName) {
      setIgnoredColumns((prevIgnored) => [...prevIgnored, columnName]);
    } else {
      setIgnoredColumns((prevIgnored) =>
        prevIgnored.filter((col) => col !== columnName)
      );
    }
  };

  const handleIgnoreColumnChange = (event, columnName) => {
    const isIgnored = event.target.checked;
    if (isIgnored) {
      setIgnoredColumns((prevIgnored) => [...prevIgnored, columnName]);
    } else {
      setIgnoredColumns((prevIgnored) =>
        prevIgnored.filter((col) => col !== columnName)
      );
    }
  };

  return (
    <div>
      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Stepper active={active} onStepClick={setActive}>
          <Stepper.Step label="First step" description="Select">
            Select a csv file to import
          </Stepper.Step>
          <Stepper.Step label="Second step" description="Map">
            Map columns with matching fields
          </Stepper.Step>
          <Stepper.Step label="Final step" description="Additional details">
            Additional details - should a task be created for each, who should
            get the task?
          </Stepper.Step>
          <Stepper.Completed>
            Completed, click back button to get to previous step
          </Stepper.Completed>
        </Stepper>
      </div>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      {data && (
        <div style={{ width: "100%", overflow: "auto" }}>
          <br></br>
          <div style={{ fontSize: 18, fontWeight: "600" }}>
            Sample 10 rows from the data:
          </div>
          <div style={{ padding: 10 }}>
            Select which column fits which data description
          </div>
          <div>
            <table style={{ border: "solid 1px black" }}>
              <thead>
                <tr style={{ padding: 5 }}>
                  {data.columns.map((column, index) => (
                    <th
                      key={index}
                      style={{ border: "solid 1px black", padding: 5 }}
                    >
                      {column.replace(/['"]+/g, "")}
                      <br />
                      <select
                        style={{ padding: 5 }}
                        value={columnMappings[column]}
                        onChange={(event) =>
                          handleColumnMappingChange(event, column)
                        }
                      >
                        <option value=""></option>
                        <option value="client_name">Client Name</option>
                        <option value="client_surname">Client Surname</option>
                        {/* Add more options for other columns */}
                      </select>
                      <br />
                      {/* <label>
                        <input
                        style={{marginRight: 5}}
                          type="checkbox"
                          checked={ignoredColumns.includes(column)}
                          onChange={(event) =>
                            handleIgnoreColumnChange(event, column)
                          }
                        />
                        Ignore
                      </label> */}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.split(",").map((cell, cellIndex) => (
                      <td
                        style={{
                          border: "solid 1px black",
                          padding: 10,
                          paddingTop: 1,
                          paddingBottom: 1,
                        }}
                        key={cellIndex}
                      >
                        {cell.replace(/['"]+/g, "")}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminScreen;
