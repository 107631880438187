export const messages = {
    'en-GB': {
        not_found: "No results found..",
        modify: "Modify ",
        create_new_user: "Create New User",
        username: "Username",
        name: "Name",
        surname: "Surname",
        email: "E-mail",
        password: "Password",
        status: "Status",
        disabled: "Disabled",
        active: "Active",
        group_id: "Group Id",
        admin: "Admin",
        user: "User",
        lead: "Lead",
        cancel: "Cancel",
        update_user: "Update user",
        create_user: "Create user",
        select_reason: "Lūdzu izvēlies iemeslu"
    }
}