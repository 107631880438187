import React, { useState, useEffect } from "react";
import { AppShell, Navbar, Header, Title } from "@mantine/core";
import { MegaMenu } from "primereact/megamenu";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Grid, Card, UnstyledButton, Group, Avatar, Text } from "@mantine/core";
import { EditableLabels } from "../../Components/Inputs/EditableLabels";

const BussinessScreen = () => {
  const items = [
    {
      label: "Apstrādāt",
      icon: "pi pi-fw pi-pencil",
      items: [
        [
          {
            items: [
              { label: "Izmainīt klientu" },
              { label: "Aizvērt klientu" },
            ],
          },
        ],
      ],
    },
    {
      label: "Darbinieki",
      icon: "pi pi-fw pi-users",
      items: [
        [
          {
            label: "User 1",
            items: [{ label: "User 1.1" }, { label: "User 1.2" }],
          },
          {
            label: "User 2",
            items: [{ label: "User 2.1" }, { label: "User 2.2" }],
          },
        ],
        [
          {
            label: "User 3",
            items: [{ label: "User 3.1" }, { label: "User 3.2" }],
          },
          {
            label: "User 4",
            items: [{ label: "User 4.1" }, { label: "User 4.2" }],
          },
        ],
        [
          {
            label: "User 5",
            items: [{ label: "User 5.1" }, { label: "User 5.2" }],
          },
          {
            label: "User 6",
            items: [{ label: "User 6.1" }, { label: "User 6.2" }],
          },
        ],
      ],
    },
    {
      label: "Events",
      icon: "pi pi-fw pi-calendar",
      items: [
        [
          {
            label: "Event 1",
            items: [{ label: "Event 1.1" }, { label: "Event 1.2" }],
          },
          {
            label: "Event 2",
            items: [{ label: "Event 2.1" }, { label: "Event 2.2" }],
          },
        ],
        [
          {
            label: "Event 3",
            items: [{ label: "Event 3.1" }, { label: "Event 3.2" }],
          },
          {
            label: "Event 4",
            items: [{ label: "Event 4.1" }, { label: "Event 4.2" }],
          },
        ],
      ],
    },
    {
      label: "Settings",
      icon: "pi pi-fw pi-cog",
      items: [
        [
          {
            label: "Setting 1",
            items: [{ label: "Setting 1.1" }, { label: "Setting 1.2" }],
          },
          {
            label: "Setting 2",
            items: [{ label: "Setting 2.1" }, { label: "Setting 2.2" }],
          },
          {
            label: "Setting 3",
            items: [{ label: "Setting 3.1" }, { label: "Setting 3.2" }],
          },
        ],
        [
          {
            label: "Technology 4",
            items: [{ label: "Setting 4.1" }, { label: "Setting 4.2" }],
          },
        ],
      ],
    },
  ];

  const darbinieki = [
    {
      c_name: "Jānis",
      c_surname: "Pētersons",
      c_phone: "+37120040000",
      c_email: "ee@gmail.lv",
    },
    {
      c_name: "Bobs",
      c_surname: "Bilders",
      c_phone: "+37120040000",
      c_email: "",
    },
  ];

  function getInitials(c_name, c_surname) {
    let initials = "";

    if (c_name && c_surname) {
      // Both c_name and c_surname are not empty
      initials = c_name.charAt(0) + c_surname.charAt(0);
    } else if (c_name) {
      // Only c_name is not empty
      initials = c_name.substring(0, 2);
    } else if (c_surname) {
      // Only c_surname is not empty
      initials = c_surname.substring(0, 2);
    }

    return initials;
  }

  const edit = false;
  return (
    <div style={{ padding: 5 }}>
      <div className="card">
        <MegaMenu model={items} breakpoint="960px" />
      </div>
      <Grid style={{ paddingTop: 20 }}>
        <Grid.Col xs={4}>
          <Card
            withBorder
            radius="md"
            style={{ maxHeight: 600, overflow: "auto" }}
          >
            <Title order={4}>Biznesa info</Title>
            <EditableLabels
              edit={edit}
              label="Juridiskais nosaukums"
              placeholder="John"
              data={"SIA THIS"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label="Reg.kods"
              placeholder="John"
              data={"LV102341214"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label="PVN"
              placeholder="John"
              data={"321102341214"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label="Jur.Addrese"
              placeholder="John"
              data={"Rīga, Rīgas iela 52"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label="Fakt.Addrese"
              placeholder="John"
              data={"Rīga, Rīgas iela 52"}
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
            <EditableLabels
              edit={edit}
              label="Apraksts"
              placeholder="John"
              data={
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. "
              }
              withAsterisk={true}
              onInputChange={(val) => console.log(val)}
            />
          </Card>
        </Grid.Col>
        <Grid.Col xs={4} style={{ maxHeight: 600, overflow: "auto" }}>
          <Card withBorder radius="md">
            <Title order={4}>Darbinieki</Title>
            {darbinieki &&
              darbinieki.map((d) => (
                <UnstyledButton>
                  <Group>
                    <Avatar size={40} color="blue">
                      {getInitials(d.c_name, d.c_surname)}
                    </Avatar>
                    <div>
                      <Text>
                        {d.c_name} {d.c_surname}
                      </Text>
                      <Text size="xs" color="dimmed">
                        {d.c_email ? d.c_email : d.c_phone ? d.c_phone : ""}
                      </Text>
                    </div>
                  </Group>
                </UnstyledButton>
              ))}
          </Card>
        </Grid.Col>
        <Grid.Col xs={4} style={{ maxHeight: 600, overflow: "auto" }}>
          <Card withBorder radius="md">
            <Title order={4}>Uzdevumi</Title>
          </Card>
        </Grid.Col>
        <Grid.Col xs={8}>
          <Card withBorder radius="md">
            <Title order={4}>Darbinieki</Title>
          </Card>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default BussinessScreen;
