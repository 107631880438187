import { useEffect, useState } from "react";
import { createCause, getCauses } from "../../functions/api";
import { Card, TextInput, Button, Switch } from "@mantine/core";

const CauseScreen = () => {
  const [causes, setCauses] = useState();
  const [newcause, setNewcause] = useState();

  useEffect(() => {
    getCauses().then((x) => {
      setCauses(x);
    });
  }, []);

  const handleSwitch = (state, value) => {
    let temp = causes;
    temp[value]["active"] = temp[value]["active"] == "0" ? "1" : "0";
    setCauses(temp);
  };

  const submit = () => {
    createCause({ cause: newcause }).then((x) => {
      setNewcause("");
      getCauses().then((x) => {
        setCauses(x);
      });
    });
  };

  return (
    <div>
      <div style={{ padding: 5, marginTop: 30, marginBottom: 50 }}>
        <TextInput
          label="Pievienot jaunu iemeslu"
          value={newcause}
          onChange={(e) => setNewcause(e.target.value)}
        />
        <Button
          onClick={() => submit()}
          style={{ backgroundColor: "dodgerblue", marginTop: 10 }}
        >
          Pievienot
        </Button>
      </div>
      <div style={{ height: 900, overflow: "auto" }}>
        {causes &&
          causes?.map((x, index) => (
            <Card
              key={x.value}
              withBorder
              shadow="sm"
              style={{
                margin: 3,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{x.label}</span>
              {/* <Switch
                checked={x.active == "0" ? false : true}
                onChange={(event) =>
                  handleSwitch(event.currentTarget.checked, index)
                }
              /> */}
            </Card>
          ))}
      </div>
    </div>
  );
};

export default CauseScreen;
