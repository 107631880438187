import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Input,
  Select,
  Button,
  Typography,
} from "@material-ui/core";
import { createUser, getUsers, updateUser } from "../../functions/api";
import CreateUserModal from "../../Components/Overlays/CreateUserModal";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  searchFilter: {
    // [theme.breakpoints.down("sm")]: {
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    // },
  },
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
    },
  },
  tableHeadCell: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    cursor: "pointer",
  },
  tableCell: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    cursor: "pointer",
  },
}));

const AdminUsers = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [accessFilter, setAccessFilter] = useState("");
  const [groupIdFilter, setGroupIdFilter] = useState(null); // set default value to null
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("username");
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUsers(
        searchTerm,
        statusFilter,
        accessFilter,
        groupIdFilter,
        sortOrder,
        sortColumn
      );
      setData(response);
    };
    fetchData();
  }, [
    searchTerm,
    statusFilter,
    accessFilter,
    groupIdFilter,
    sortOrder,
    sortColumn,
    open,
    user,
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUser(null);
    setOpen(false);
  };

  const openModal = (data) => {
    setUser(data);
    setOpen(true);
  };

  const handleUser = (data) => {
    setUser(null);
    setOpen(false);
    data.userid ? updateUser(data) : createUser(data);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  return (
    <div className={classes.root}>
      <CreateUserModal
        open={open}
        onClose={handleClose}
        onSubmit={handleUser}
        user={user}
      />
      <div className={classes.searchFilter}>
        <Input
          placeholder="Search users"
          value={searchTerm}
          style={{ padding: 5, margin: 5 }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Select
          value={statusFilter}
          style={{ padding: 5, margin: 5 }}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="disabled">Disabled</option>
        </Select>
        <Select
          style={{ padding: 5, margin: 5 }}
          value={groupIdFilter}
          onChange={(e) => setGroupIdFilter(e.target.value)}
        >
          <option value={null}>All</option>
          <option value="1">Admin</option>
          <option value="10">Users</option>
          <option value="20">Lead</option>
        </Select>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create User
        </Button>
      </div>
      <div className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeadCell}
                onClick={() => handleSort("username")}
              >
                Username
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                onClick={() => handleSort("name")}
              >
                Name
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                onClick={() => handleSort("surname")}
              >
                Surname
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                onClick={() => handleSort("email")}
              >
                Email
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                onClick={() => handleSort("status")}
              >
                Status
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                onClick={() => handleSort("group_id")}
              >
                Group
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row) => (
                <TableRow key={row.userid} onClick={() => openModal(row)}>
                  {/* <TableRow key={row.userid} onClick={() => window.open('/admin/user/'+row.userid, '_blank')}> */}
                  <TableCell className={classes.tableCell}>
                    {row.username}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.surname}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.email}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.status}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.group_id < 10
                      ? "Admin"
                      : row.group_id < 20
                      ? "User"
                      : "Lead"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default AdminUsers;
