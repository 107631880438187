import React, { useEffect, useState } from "react";
import {
  getApiHistory,
  getApis,
  getReportFilters,
  updateApi,
} from "../../functions/api";
import {
  Button,
  Loader,
  MultiSelect,
  Grid,
  TextInput,
  Textarea,
  Select,
  Paper,
  Text,
  Notification,
  Alert,
  Anchor,
  Switch,
} from "@mantine/core";
import {
  ChevronRight,
  Clipboard,
  AlertCircle,
  BorderBottom,
} from "tabler-icons-react";
import { useLocation } from "react-router-dom";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useClipboard } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";

export const ApiTab = () => {
  const [apis, setApis] = useState([]);
  const nav = useNavigate();

  useEffect(() => {
    getApis().then((data) => setApis(data));
  }, []);

  return (
    <div>
      <Button
        style={{ backgroundColor: "dodgerblue", marginBottom: "10px" }}
        onClick={() => nav("/admin/api/new")}
      >
        Create API
      </Button>

      {apis.map((api) => (
        <RouterLink
          to={`/admin/api/${api.apiid}`}
          key={api.apiid}
          style={{ textDecoration: "none" }}
        >
          <Paper
            padding="md"
            shadow="xs"
            style={{ marginBottom: "10px", padding: 10 }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text> {api.title}</Text>
              <Text style={{ fontSize: 13 }}> {api.history.length}</Text>
            </span>
            <Text style={{ fontSize: 12, wordBreak: "break-word" }}>
              {"https://api.jbcrm.lv/api/v0/" + api.api_link}
            </Text>
            <Text style={{ color: api.status == "active" ? "green" : "" }}>
              {api.status}
            </Text>
            <ChevronRight
              size="0.8rem"
              stroke={1.5}
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
              }}
            />
          </Paper>
        </RouterLink>
      ))}
    </div>
  );
};

export const ApiScreen = () => {
  const [filters, setFilters] = useState();
  const location = useLocation();
  const clipboard = useClipboard();
  const [active, setActive] = useState();
  const [values, setValues] = useState({ status: "disabled" });
  const [entries, setEntries] = useState(false);
  const [search, setSearch] = useState(false);
  const [safety, setSafety] = useState(false);

  const submitAPI = () => {
    if (window.confirm("Vai tiešām vēlaties saglabāt izmaiņas?")) {
      updateApi(values).then((res) =>
        window.location.assign("https://jbcrm.lv/admin/api/" + res.apiid)
      );
    }
  };

  let routes = location.pathname.split("/");

  useEffect(() => {
    getReportFilters().then((data) => setFilters(data));
  }, []);

  useEffect(() => {
    if (routes[3] != "new") {
      //   routes[3];
      getApiHistory({
        apiid: routes[3],
        creationRange: search.creationRange,
      }).then((x) => setEntries(x));
    } else {
      setEntries(false);
    }
  }, [search]);

  useEffect(() => {
    if (routes[3] != "new") {
      setSafety(true);
      getApis(routes[3]).then((d) => {
        d = d[0];
        setActive(d.status);
        setValues({
          apiid: d.apiid,
          status: d.status,
          title: d.title,
          api_link: d.api_link,
          api_key: d.api_key,
          task_name: d.task_name,
          description: d.description,
          ref_users: d.ref_users.split(",").map(Number),
          ref_source: d.ref_source.split(",").map(Number),
        });
      });
    }
  }, []);

  const handleSearch = (name) => (value) => {
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChange = (name) => (value) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const textChange = (name) => (event) => {
    setValues((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const doCopy = (data) => {
    clipboard.copy(data);
  };

  return (
    <>
      {!filters ? (
        <Loader />
      ) : (
        <>
          {routes[3] != "new" && active == "active" ? (
            <Alert
              icon={<AlertCircle size="1rem" />}
              title="API is online!"
              color="green"
            >
              API is active and is receiving requests!
            </Alert>
          ) : (
            <Alert
              icon={<AlertCircle size="1rem" />}
              title="API is offline!"
              color="red"
            >
              API is disabled and is not receiving requests
            </Alert>
          )}
          <Grid style={{ padding: 10 }}>
            {routes[3] != "new" && (
              <Grid.Col xs={12}>
                <Switch
                  style={{ float: "right", textAlign: "right" }}
                  labelPosition="left"
                  label="Safety lock"
                  description="Safety lock neļauj izmainīt API datus, kamēr ir ieslēgts"
                  color="teal"
                  checked={safety}
                  onChange={(event) => setSafety(event.currentTarget.checked)}
                />
              </Grid.Col>
            )}
            <Grid.Col xs={6}>
              <TextInput
                placeholder="API name"
                label="Api Name"
                onChange={textChange("title")}
                value={values.title}
                disabled={safety}
              />
              <span>
                {values.api_link && (
                  <span style={{ width: "100%" }}>
                    <TextInput
                      placeholder="Api link"
                      value={"https://api.jbcrm.lv/api/v0/" + values.api_link}
                      label="API link"
                      disabled
                      style={{
                        display: "inline-block",
                        maxWidth: 420,
                        minWidth: "90%",
                      }}
                    />
                    <span
                      onClick={() =>
                        doCopy(`https://api.jbcrm.lv/api/v0/${values.api_link}`)
                      }
                      style={{
                        display: "inline-block",
                        marginBottom: -5,
                        marginLeft: 7,
                        cursor: "pointer",
                      }}
                    >
                      <Clipboard />
                    </span>
                  </span>
                )}
              </span>
              <span style={{ width: "100%" }}>
                <TextInput
                  placeholder="API key"
                  value={values.api_key}
                  label="API key"
                  disabled
                  style={{
                    display: "inline-block",
                    maxWidth: 420,
                    minWidth: "90%",
                  }}
                />
                <span
                  onClick={() => doCopy(values.api_key)}
                  style={{
                    display: "inline-block",
                    marginBottom: -5,
                    marginLeft: 7,
                    cursor: "pointer",
                  }}
                >
                  <Clipboard />
                </span>
              </span>
              <Select
                label="API status"
                placeholder="Pick one"
                value={values.status}
                data={[
                  { value: "active", label: "Active" },
                  { value: "disabled", label: "Disabled" },
                ]}
                disabled={safety}
                onChange={handleChange("status")}
              />
            </Grid.Col>
            {/* <Grid.Col xs={6}></Grid.Col> */}
            <Grid.Col xs={6}>
              <MultiSelect
                disabled={safety}
                data={filters?.users}
                label="Piešķirt uzdevumu:"
                placeholder="Select status"
                searchable
                clearable
                multiple
                value={values.ref_users}
                onChange={handleChange("ref_users")}
              />
              <MultiSelect
                disabled={safety}
                data={filters?.sources}
                label="Klienta nozare"
                searchable
                clearable
                multiple
                placeholder="Select source"
                value={values.ref_source}
                onChange={handleChange("ref_source")}
              />
              <TextInput
                disabled={safety}
                placeholder=""
                label="Izveidojamā uzdevuma nosaukums"
                value={values.task_name}
                onChange={textChange("task_name")}
              />
              <Textarea
                disabled={safety}
                placeholder="Apraksts"
                label="Apraksts"
                value={values.description}
                onChange={textChange("description")}
              />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Button
                disabled={safety}
                onClick={() => submitAPI()}
                style={{ backgroundColor: "dodgerblue" }}
              >
                Save
              </Button>
            </Grid.Col>
          </Grid>
          <Paper
            shadow="xs"
            p="md"
            style={{ margin: 5, marginBottom: 50, marginTop: 30 }}
          >
            <Grid style={{}}>
              <Grid.Col xs={12}>
                <Text>Fields that API receives</Text>
              </Grid.Col>
              <Grid.Col xs={2}>
                <TextInput value="name" label="Name" readOnly />
              </Grid.Col>
              <Grid.Col xs={2}>
                <TextInput value="surname" label="Surname" readOnly />
              </Grid.Col>
              <Grid.Col xs={2}>
                <TextInput value="tel" label="Telephone" readOnly />
              </Grid.Col>
              <Grid.Col xs={2}>
                <TextInput value="email" label="E-mail" readOnly />
              </Grid.Col>
              <Grid.Col xs={2}>
                <TextInput value="description" label="Description" readOnly />
              </Grid.Col>
            </Grid>
          </Paper>
          <div style={{ padding: 5 }}>
            {entries && (
              <div style={{ fontSize: 16, fontWeight: 800 }}>API klienti</div>
            )}
            <Grid style={{ marginBottom: 200 }}>
              {entries && (
                <>
                  <Grid.Col xs={4}>
                    <DatePickerInput
                      clearable
                      type="range"
                      label="Izveidošanas laiks"
                      placeholder="Pick date range"
                      value={search.creationRange}
                      onChange={handleSearch("creationRange")}
                      mx="auto"
                    />
                  </Grid.Col>
                  <Grid.Col xs={4}>Kopā {entries.length}</Grid.Col>
                </>
              )}
              {entries &&
                entries.map((x) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 10,
                      width: "100%",
                      backgroundColor: x?.c_closed ? "#ff000025" : "#dedede50",
                      borderBottom: "0.5px solid grey",
                    }}
                  >
                    <Anchor
                      href={"/partner/" + x.clientid}
                      target="_blank"
                      style={{
                        minWidth: "15%",
                        textDecoration: x?.c_closed
                          ? "line-through"
                          : "inherit",
                      }}
                    >
                      {x?.c_name} {x?.c_surname}
                    </Anchor>
                    <span>{x?.reason} </span>
                    <span> {x?.created?.substring(0, 10)}</span>
                  </div>
                ))}
            </Grid>
          </div>
        </>
      )}
      {clipboard.copied && (
        <Notification
          title="Copied to clipboard"
          onClose={() => clipboard.clear()}
          color="green"
          shadow
          closeButton
          style={{ position: "absolute", top: 75, right: 20 }}
        >
          Successfully copied to the clipboard.
        </Notification>
      )}
    </>
  );
};

export default ApiScreen;
