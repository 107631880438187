import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { Button as MantineButton } from "@mantine/core";
import { getUser, getClients, createNewTask } from "../../functions/api";
import AutoSelect from "../../Components/Inputs/AutoSelect";
import LocationAutocomplete from "../../functions/LocationAutocomplete";

const AddTaskScreen = () => {
  const [assignedUser, setAssignedUser] = useState("");
  const [client, setClient] = useState("");
  const [deadline, setDeadline] = useState("");
  const [taskName, setTaskName] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState();

  const handleAddTask = async () => {
    const data = {
      assignedUser,
      client,
      deadline,
      taskName,
      description,
      address: address,
    };
    const createdTask = await createNewTask(data);
    if (createdTask) {
      window.location.reload();
    }
  };

  return (
    <Box p={4} bg="gray.50" borderRadius="md">
      <VStack spacing={4} align="stretch">
        <FormControl>
          <AutoSelect
            title="Assigned User"
            placeholder="search for a user"
            getData={getUser}
            callBack={setAssignedUser}
          />
        </FormControl>

        <FormControl>
          <AutoSelect
            title="Client"
            placeholder="search for a client"
            getData={(data) => getClients(data, true)}
            callBack={setClient}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="lg">Deadline</FormLabel>
          <Input
            type="datetime-local"
            min={new Date().toISOString().slice(0, 16)}
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            size="lg"
            variant="filled"
            style={{ width: "100%" }}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize="lg">Task Name</FormLabel>
          <Input
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            size="lg"
            variant="filled"
            style={{ padding: 10, border: "solid 1px grey", width: "100%" }}
          />
        </FormControl>
        <LocationAutocomplete callBack={setAddress} />

        <FormControl>
          <FormLabel fontSize="lg">Description</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            size="lg"
            width={"100%"}
            minHeight="100px"
            variant="filled"
          />
        </FormControl>
        <MantineButton
          onClick={handleAddTask}
          size="lg"
          style={{ backgroundColor: "blue" }}
        >
          Add Task
        </MantineButton>
      </VStack>
    </Box>
  );
};

export default AddTaskScreen;
