import { Input } from "@mantine/core";
import { usePlacesWidget } from "react-google-autocomplete";

const LocationAutocomplete = ({ callBack }) => {
  const { ref, autocompleteRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      callBack(place);
    },
    options: {
      types: ["address"],
    },
  });

  return (
    <>
      <div style={{ marginTop: 15 }}>
        Add a location
        <div>
          <Input
            options={{
              types: ["address"],
            }}
            placeholder="Type address here"
            ref={ref}
          />
        </div>
      </div>
    </>
  );
};

export default LocationAutocomplete;
