import { useEffect, useState } from "react";
import { changePassword, getUser } from "../../functions/api";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button, Group, LoadingOverlay } from "@mantine/core";
import { Check } from "tabler-icons-react";

export default function ProfileScreen() {
  const [user, setUser] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [visible, setVisible] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);

    if (event.target.value !== repeatPassword) {
      setErrorMessage("New password and repeat password do not match");
    } else {
      setErrorMessage(null);
    }
  };

  const handleRepeatPasswordChange = (event) => {
    setRepeatPassword(event.target.value);

    if (event.target.value !== newPassword) {
      setErrorMessage("New password and repeat password do not match");
    } else {
      setErrorMessage(null);
    }
  };
  useEffect(() => {
    getUser(null).then((d) => setUser(d ? d[0] : d));
  }, []);

  const setReceivedResult = (data) => {
    setResult(data);
    console.log(result);
    setVisible(false);
  };

  const saveNewPassword = () => {
    if (newPassword && oldPassword) {
      if (!errorMessage) {
        setVisible(true);
        const send = {
          pass: oldPassword,
          new: newPassword,
          repeat: repeatPassword,
        };
        changePassword(send).then((d) => {
          if (d.message == "win") {
            setResult("win");
          } else {
            if (d.message == "wrong") {
              window.alert("Password not correct!");
            }
            if (d.message == "no-match") {
              window.alert("Passwords don't match!");
            }
          }
          setVisible(false);
        });
      } else {
        window.alert("New password and repeat password do not match");
      }
    } else {
      window.alert("Password missing");
    }
  };

  return (
    <>
      <div>
        <div
          className="md:grid md:gap-6"
          style={{
            maxWidth: 900,
            margin: "auto",
            marginTop: 50,
            marginBottom: 200,
          }}
        >
          <Modal opened={opened} onClose={close} title="Change password">
            <LoadingOverlay visible={visible} overlayBlur={2} />
            <div className="col-span-6 sm:col-span-3 pt-3">
              <label
                htmlFor="old-pass"
                className="block text-sm font-medium text-gray-700"
              >
                Old Password
              </label>
              <input
                type="password"
                name="old-pass"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="new-pass"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <input
                type="password"
                name="new-pass"
                value={newPassword}
                onChange={handleNewPasswordChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="repeat-pass"
                className="block text-sm font-medium text-gray-700"
              >
                Repeat Password
              </label>
              <input
                type="password"
                name="repeat-pass"
                value={repeatPassword}
                onChange={handleRepeatPasswordChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <div style={{ textAlign: "right" }}>
              {result == "win" ? (
                <Button onClick={close} color="teal" style={{ marginTop: 15, paddingLeft: 40, paddingRight: 40, backgroundColor: "limegreen"  }}>
                  <Check style={{ marginRight: 5}} />
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "dodgerblue", marginTop: 15 }}
                  onClick={() => saveNewPassword()}
                >
                  Save password
                </Button>
              )}
            </div>
          </Modal>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="shadow sm:overflow-hidden">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Photo
                    </label>
                    <div className="mt-1 flex items-center">
                      <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                        <svg
                          className="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                      <button
                        type="button"
                        className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="shadow sm:overflow-hidden">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="col-span-6 sm:col-span-3 pb-5">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        disabled
                        name="first-name"
                        value={user?.username}
                        autoComplete="given-name"
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          name="first-name"
                          value={user?.name}
                          autoComplete="given-name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          name="last-name"
                          value={user?.surname}
                          autoComplete="family-name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email-address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <input
                          type="text"
                          name="email-address"
                          value={user?.email}
                          autoComplete="email"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="pt-6 text-right col-span-6 sm:col-span-3">
                        {/* <hr style={{ marginBottom: 10 }} /> */}
                        <Button
                          className="block w-full"
                          style={{ backgroundColor: "dodgerblue" }}
                          onClick={open}
                        >
                          Change password
                        </Button>
                        {/* <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="overflow-hidden shadow sm:rounded-md">
                {/* <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <fieldset>
                    <legend className="sr-only">By Email</legend>
                    <div
                      className="text-base font-medium text-gray-900"
                      aria-hidden="true"
                    >
                      By Email
                    </div>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Comments
                          </label>
                          <p className="text-gray-500">
                            Get notified when someones posts a comment on a
                            posting.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="candidates"
                            name="candidates"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="candidates"
                            className="font-medium text-gray-700"
                          >
                            Candidates
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate applies for a job.
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="offers"
                            name="offers"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="offers"
                            className="font-medium text-gray-700"
                          >
                            Offers
                          </label>
                          <p className="text-gray-500">
                            Get notified when a candidate accepts or rejects an
                            offer.
                          </p>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="contents text-base font-medium text-gray-900">
                      Push Notifications
                    </legend>
                    <p className="text-sm text-gray-500">
                      These are delivered via SMS to your mobile phone.
                    </p>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="push-everything"
                          name="push-notifications"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="push-everything"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Everything
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-email"
                          name="push-notifications"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="push-email"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          Same as email
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="push-nothing"
                          name="push-notifications"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor="push-nothing"
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          No push notifications
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div> */}
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <Button style={{ backgroundColor: "dodgerblue" }}>
                    Save profile changes
                  </Button>
                  {/* <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
