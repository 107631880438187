import { useState, useEffect } from "react";
import { PaperClipIcon } from "@heroicons/react/solid";
import { getPin, togglePin } from "../functions/api";
import { Button, Loader } from "@mantine/core";

function PinButton({ itemType, itemId, pinId, callBack }) {
  const [activePinId, setActivePinId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Send a request to getPin to determine if the button should be active on load
    const fetchData = async () => {
      try {
        const response = await getPin({ itemType, itemId });
        const data = await response;
        setActivePinId(data.pinId ? true : false);
        callBack && callBack(data.pinId)
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [itemType, itemId]);

  const togglePinButton = async () => {
    setIsLoading(true);
    try {
      //const newPinId = activePinId === pinId ? null : pinId;
      const response = await togglePin({ itemType, itemId });
      setActivePinId(!activePinId);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Button
      color={activePinId ? "red" : "gray"}
      disabled={isLoading}
      onClick={togglePinButton}
      variant="outline"
      radius="xl"
      leftIcon={
        isLoading ? (
          <Loader size={16} />
        ) : (
          <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
        )
      }
    >
      {activePinId ? "Unpin" : "Pin"}
    </Button>
  );
}

export default PinButton;
