import { useState, useEffect } from "react";
import {
  useMantineTheme,
  Text,
  TextInput,
  FileInput,
  Button,
} from "@mantine/core";
import { getClients, sendFile } from "../../functions/api";
import AutoSelect from "../../Components/Inputs/AutoSelect";

function AddDocumentScreen({ preclient }) {
  const theme = useMantineTheme();
  const [client, setClient] = useState("");
  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    if (preclient) {
      setClient(preclient);
    }
  }, []);

  const submitCreateInvoice = async () => {
    if (!file) {
      return window.alert("Add a file!");
    }
    if (!name) {
      return window.alert("Add a name!");
    }

    if (file) {
      sendFile(file, { name, client }).then((x) => {
        x.docId ? window.location.reload(false) : window.alert("error!");
      });
    }
  };

  return (
    <div style={{ padding: theme.spacing.md }}>
      <Text style={{ fontSize: theme.fontSizes.xl }}>Add a new file</Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.sm,
          marginTop: theme.spacing.md,
        }}
      >
        <AutoSelect
          title="Select client"
          placeholder="search for a client"
          preselected={preclient ? preclient : null}
          getData={(data) => getClients(data, true)}
          callBack={setClient}
        />
        <TextInput
          label="File name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FileInput
          label="Upload file"
          placeholder="Upload file"
          value={file}
          onChange={setFile}
        />
        <Button
          variant="filled"
          onClick={() => submitCreateInvoice()}
          style={{ backgroundColor: "blue" }}
          fullWidth
        >
          Upload file
        </Button>
      </div>
    </div>
  );
}

export default AddDocumentScreen;
