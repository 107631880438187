import React, { useState, useEffect } from "react";
import {
  Text,
  Input,
  Grid,
  Col,
  Button,
  Dialog,
  Group,
  Badge,
  Anchor,
  Overlay,
} from "@mantine/core";
import {
  getInvoice,
  getReportFilters,
  triggerInvoicePaid,
  triggerInvoiceSend,
  triggerInvoiceVoid,
  updateInvoice,
} from "../../functions/api";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { DateInput, DatePickerInput } from "@mantine/dates";
import SideOverlay from "../../Components/Overlays/SideOverlay";
import AddInvoiceScreen from "./AddInvoiceScreen";
import PDFscreen, { PDFdoc, returnPDFdoc } from "./PDFscreen";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { getInvoiceStart } from "../../functions/misc";
import { Link } from "react-router-dom";
import { ExternalLink } from "tabler-icons-react";
import { getGroup } from "../../functions/tokens";
import { CallButton } from "../../Components/CallButton";
import hasAccess from "../../functions/access";
import { Comment } from "../../Components/Comment";
import { InvoicesReport } from "../Admin/AdminReports";
// import Overlay from "../../Components/Overlays/Overlay";
const showPDF = (data) => {
  //alert("implement PDF");
  PDFscreen(data);
};

const InvoicesPage = () => {
  const [filters, setFilters] = useState();
  useEffect(() => {
    getReportFilters().then((x) => setFilters(x));
  }, []);
  return <InvoicesReport filters={filters} />;
};

export const ModifyInvoiceModal = ({
  isModalOpen,
  selectedInvoice,
  callBack,
}) => {
  const [newPaidAmount, setNewPaidAmount] = useState("");
  const [extended, setExtended] = useState(null);
  const [status, setStatus] = useState();
  const [tmpSts, setTmpsts] = useState("");
  const [paidDate, setPaidDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  let group = getGroup();
  group = group == null ? 10 : group;
  const callSend = async () => {
    if (window.confirm("Are you sure you want to send to client?")) {
      setStatus("wait");
      getInvoice({ invoice_id: selectedInvoice.invoice_id }).then(
        async (dx) => {
          try {
            const pdfBlob = await pdf(returnPDFdoc(dx)).toBlob();
            const ret = await triggerInvoiceSend(
              pdfBlob,
              selectedInvoice.invoice_id
            );

            if ((await ret.message) == "WIN") {
              setStatus("sent");
              setTmpsts("send-win");
            }
          } catch (e) {
            console.log(e);
          }
        }
      );
    }
  };

  const callPaid = () => {
    if (hasAccess(group, "markInvoices", "edit")) {
      if (
        window.confirm(
          "Are you want to mark this paid in full amount of " +
            selectedInvoice.total_vat +
            "?"
        )
      ) {
        handlePaidAmountSave(selectedInvoice.invoice_id, "paid");

        setOpen(false);
        callBack(false);
        // triggerInvoicePaid(selectedInvoice.invoice_id).then((x) => {
        //   console.log(x);
        //   setStatus("paid");
        //   setOpen(false);
        //   callBack(false);
        // });
      }
    }
  };

  const callVoid = () => {
    if (window.confirm("Vai esi drošs, ka vēlies annulēt šo rēķinu?")) {
      triggerInvoiceVoid(selectedInvoice.invoice_id);
      setStatus("void");
    }
  };

  const handlePaidAmountSave = (id, newStatus) => {
    updateInvoice({
      extended_due: extended,
      paid_amount: newPaidAmount,
      paid_date: paidDate,
      id: id,
      status: newStatus ? newStatus : status,
    });
    callBack(false);
  };

  useEffect(() => {
    if (selectedInvoice.paid_amount > 0) {
      setNewPaidAmount(selectedInvoice.paid_amount);
    }
    if (selectedInvoice.extended_due != null) {
      setExtended(new Date(selectedInvoice.extended_due));
    }
    setStatus(selectedInvoice.status);
  }, []);

  const handleModalClose = () => {
    callBack(false);
    //setNewPaidAmount("");
  };

  const handlePaidAmountChange = (event) => {
    setNewPaidAmount(event.target.value);
  };

  return (
    <div>
      <Dialog
        opened={open}
        style={{ margin: 30 }}
        withCloseButton
        onClose={() => setOpen(false)}
        size="lg"
        radius="md"
        position={{ top: 300 }}
      >
        <Text size="sm" mb="xs" weight={500}>
          Ievadiet Apmaksas datumu:
        </Text>

        <Group align="flex-end">
          <DatePickerInput
            style={{ flex: 1 }}
            placeholder="Izvēlies datumu"
            value={paidDate}
            onChange={setPaidDate}
            mx="auto"
          />
          <Button style={{ backgroundColor: "dodgerblue" }} onClick={callPaid}>
            Apstiprināt
          </Button>
        </Group>
      </Dialog>

      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        closeOnClickOutside={true}
      >
        <ModalOverlay style={{ backgroundColor: "#00000080" }} />
        <ModalContent
          style={{
            backgroundColor: "white",
            padding: 20,
            margin: "5%",
            marginTop: "10%",
          }}
        >
          <ModalHeader>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <>Edit Invoice: {selectedInvoice.invoice_name}</>{" "}
              {selectedInvoice.status == "void" &&
                hasAccess(group, "markInvoices", "delete") && (
                  <Badge color={"red"} variant="filled">
                    Annulēts
                  </Badge>
                )}
            </div>
          </ModalHeader>
          {selectedInvoice.made_for && (
            <Link
              to={{
                pathname: `/partner/${selectedInvoice.made_for}`,
                query: { foo: "bar" },
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "dodgerblue" }}
            >
              <ExternalLink
                size={16}
                style={{
                  display: "inline-block",
                  paddingBottom: 1,
                }}
              />
              <Text
                style={{
                  display: "inline-block",
                  fontSize: 14,
                  textDecorationLine: "underline",
                }}
              >
                {" "}
                View client in new tab
              </Text>
            </Link>
          )}
          <ModalBody>
            <Grid gutter="lg">
              <Col span={6}>
                <Text fontWeight="bold">Issued To:</Text>
                <Input
                  value={
                    selectedInvoice.c_name + " " + selectedInvoice.c_surname
                  }
                  disabled
                  variant="default"
                  size="sm"
                />
              </Col>
              <Col span={6}>
                <Text fontWeight="bold">Invoiced For:</Text>
                <Input
                  value={selectedInvoice.invoiced_for}
                  disabled
                  variant="default"
                  size="sm"
                />
              </Col>
              <Col span={6}>
                <Text fontWeight="bold">Issued By:</Text>
                <Input
                  value={
                    selectedInvoice.created_by_name +
                    " " +
                    selectedInvoice.created_by_surname
                  }
                  disabled
                  variant="default"
                  size="sm"
                />
              </Col>
              <Col span={6}>
                <Text fontWeight="bold">Total VAT:</Text>
                <Input
                  value={selectedInvoice.total_vat}
                  disabled
                  variant="default"
                  size="sm"
                />
              </Col>
              <Col span={6}>
                <Text fontWeight="bold">Paid Amount:</Text>
                <Input
                  value={newPaidAmount}
                  onChange={handlePaidAmountChange}
                  variant="default"
                  size="sm"
                  disabled={
                    selectedInvoice.paid_date
                      ? true
                      : hasAccess(group, "markInvoices", "edit")
                      ? false
                      : true
                  }
                />
              </Col>
              <Col span={6}>
                <Text fontWeight="bold">Deadline extended to:</Text>
                <DateInput
                  minDate={new Date()}
                  value={extended}
                  onChange={setExtended}
                  disabled={selectedInvoice.paid_date ? true : false}
                  disabledx={selectedInvoice.paid_date ? true : false}
                />
              </Col>
              <Col span={12}>
                {status && status != "paid" ? (
                  <>
                    {" "}
                    Actions
                    <div style={{ paddingTop: 15 }}>
                      {status != "void" && (
                        <Button
                          onClick={() => callVoid(selectedInvoice)}
                          style={{ backgroundColor: "red", marginRight: 10 }}
                        >
                          Annulēt
                        </Button>
                      )}
                      {status &&
                        status == "draft" &&
                        (selectedInvoice?.client?.c_email ||
                        selectedInvoice?.c_email ? (
                          <Button
                            style={{ backgroundColor: "dodgerblue" }}
                            onClick={() => callSend(selectedInvoice)}
                            mr={3}
                          >
                            Send to client
                          </Button>
                        ) : (
                          <p style={{ fontWeight: "bold" }}>
                            Sūtīšanai pievienojiet klienta kartiņā e-pastu!
                          </p>
                        ))}
                      {status && tmpSts == "" && status == "sent" && (
                        <Button
                          style={{ backgroundColor: "dodgerblue" }}
                          onClick={() => setOpen(true)}
                          mr={3}
                        >
                          Mark as Paid
                        </Button>
                      )}
                      {status && tmpSts == "send-win" && (
                        <div
                          style={{
                            backgroundColor: "lightgrey",
                            padding: 10,
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          Successfully sent!
                        </div>
                      )}
                      {status && status == "wait" && (
                        <div
                          style={{
                            backgroundColor: "lightgrey",
                            padding: 10,
                            color: "black",
                          }}
                        >
                          {" "}
                          Sending...
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    Invoice paid & closed at:{" "}
                    {selectedInvoice.paid_date &&
                      new Date(selectedInvoice.paid_date).toLocaleString(
                        "en-US",
                        {
                          dateStyle: "short",
                          timeStyle: "short",
                        }
                      )}
                  </>
                )}
              </Col>
            </Grid>
          </ModalBody>
          <ModalFooter
            style={{
              marginTop: 15,
            }}
          >
            <Anchor
              href={"/invoices/" + selectedInvoice.invoice_id}
              target="_blank"
              style={{ marginRight: 15 }}
            >
              Pievienot komentāru
            </Anchor>
            <PDFDownloadLink
              document={<PDFdoc id={selectedInvoice.invoice_id} />}
              style={{
                cursor: "pointer",
                color: "orange",
                display: "inline",
              }}
              fileName={"INV" + selectedInvoice.invoice_name + "invoice.pdf"}
            >
              {({ blob, url, loading, error }) =>
                !loading && (
                  <Button style={{ backgroundColor: "green" }} mr={3}>
                    View PDF
                  </Button>
                )
              }
            </PDFDownloadLink>
            {!selectedInvoice.paid_date && (
              <Button
                style={{ backgroundColor: "dodgerblue" }}
                onClick={() => handlePaidAmountSave(selectedInvoice.invoice_id)}
                mr={3}
              >
                Save
              </Button>
            )}
            <Button onClick={handleModalClose} variant="ghost">
              Cancel
            </Button>
          </ModalFooter>
          <div style={{ marginTop: 20 }}>
            {selectedInvoice &&
              selectedInvoice.comments &&
              selectedInvoice.comments[0].comment != null &&
              selectedInvoice.comments.map((x) => (
                <Comment
                  postedAt={x?.created}
                  body={x?.comment}
                  author={x?.name + " " + x?.surname}
                />
              ))}
          </div>
          {open && <Overlay color="#000" opacity={0.35} />}
        </ModalContent>
      </Modal>
    </div>
  );
};

export const InvoiceListItem = () => {};

export default InvoicesPage;
