import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserAdmin } from "../../functions/api";
import {
  Button,
  Card,
  Grid,
  LoadingOverlay,
  Select,
  TextInput,
  Title,
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

const AdminUserScreen = () => {
  const { key } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const { height, width } = useViewportSize();

  useEffect(() => {
    // setLoading(true);
    getUserAdmin({ userId: key }).then((x) => setUser(x));
    setLoading(false);
  }, []);

  return (
    <>
      <div style={{ position: "relative", height: height }}>
        {loading && !user && (
          <LoadingOverlay visible={loading} overlayBlur={2} />
        )}
        <div style={{ padding: 20 }}>
          {!loading && user && (
            <Card shadow="sm" withBorder>
              <Grid>
                <Grid.Col sm={12}>
                  <Title order={3}>Lietotāja pamatdati</Title>
                </Grid.Col>
                <Grid.Col sm={3}>
                  <TextInput value={user.username} label="Username" />
                </Grid.Col>
                <Grid.Col sm={3}>
                  <TextInput value={user.name} label="Name" />
                </Grid.Col>
                <Grid.Col sm={3}>
                  <TextInput value={user.surname} label="Surname" />
                </Grid.Col>
                <Grid.Col sm={3}>
                  <TextInput value={user.email} label="E-mail" />
                </Grid.Col>
                <Grid.Col
                  sm={3}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button style={{ backgroundColor: "dodgerblue" }}>
                    Saglabāt
                  </Button>
                  <Button
                    style={{
                      marginLeft: 5,
                      backgroundColor:
                        user.status == "active" ? "red" : "green",
                    }}
                  >
                    {user.status == "active"
                      ? "Deaktivizēt lietotāju"
                      : "Aktivizēt lietotāju"}
                  </Button>
                </Grid.Col>
              </Grid>
            </Card>
          )}
          {user && (
            <div style={{ marginTop: 30 }}>
              <Grid>
                <Grid.Col sm={12}>
                  <Title order={3}>Lietotāja nosacījumi</Title>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <Card shadow="sm" withBorder>
                    <Grid>
                      <Grid.Col sm={10}>
                        <Select
                          withinPortal={true}
                          label="Lietotāja grupa"
                          placeholder="Pick one"
                          data={[
                            { value: 0, label: "Admin" },
                            { value: 1, label: "Supervisor" },
                            { value: 10, label: "Lietotājs" },
                            { value: 20, label: "SOME" },
                          ]}
                        />
                      </Grid.Col>
                      <Grid.Col sm={2}>
                        <Button
                          style={{
                            backgroundColor: "dodgerblue",
                            marginTop: 25,
                          }}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    </Grid>
                  </Card>
                </Grid.Col>

                <Grid.Col sm={12}>
                  <Card withBorder shadow="sm">
                    <Grid></Grid>
                  </Card>
                </Grid.Col>
              </Grid>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminUserScreen;
