import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Input,
  Select,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
  Tooltip,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { createInvoice, getBank, getClients } from "../../functions/api";
import AutoSelect from "../../Components/Inputs/AutoSelect";

const serviceOptions = [
  { value: "Consultation", label: "Consultation" },
  { value: "Design", label: "Design" },
  { value: "Development", label: "Development" },
  // add more options here
];

function AddInvoiceScreen({ preclient, assignee }) {
  const theme = useMantineTheme();
  const [client, setClient] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [services, setServices] = useState([{ name: "Līguma noformēšana ar garantiju par lietas ierosināšanu", cost: 41.32 }]);
  // const [services, setServices] = useState([{ name: "", cost: 0 }]);
  const [notes, setNotes] = useState("");
  const [taxRate, setTaxRate] = useState(0.21);
  const [bank, setBank] = useState(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (preclient) {
      setClient(preclient);
    }
  }, []);

  const handleAddService = () => {
    setServices([...services, { name: "", cost: 0 }]);
  };

  const submitCreateInvoice = async () => {
    if (!client) {
      return window.alert("Select a client!");
    }
    if (!services) {
      return window.alert("Add a service!");
    }
    if (!bank) {
      return window.alert("Select credentials!");
    }

    createInvoice({ client, dueDate, services, notes, taxRate, bank }).then(
      (data) => window.location.reload(false)
    );
  };

  const handleRemoveService = (index) => {
    const newServices = [...services];
    newServices.splice(index, 1);
    setServices(newServices);
  };

  const handleServiceChange = (value, index, key) => {
    const newServices = [...services];
    if (value && value === "other") {
      newServices[index][key] = "";
      newServices[index]["customServiceName"] = true;
    } else if (value) {
      newServices[index][key] = value;
      newServices[index]["customServiceName"] = false;
    } else {
      newServices[index][key] = "";
      newServices[index]["customServiceName"] = false;
    }
    setServices(newServices);
  };

  const handleCustomServiceNameChange = (event, index) => {
    const newServices = [...services];
    newServices[index]["name"] = event.target.value;
    setServices(newServices);
  };

  const handleDueDateChange = (value) => {
    setDueDate(value);
  };

  const subtotal = services.reduce(
    (total, service) => Number(total) + Number(service.cost),
    0
  );
  const amountDue = subtotal * (1 + Number(taxRate));

  return (
    <div style={{ padding: theme.spacing.md }}>
      <Text style={{ fontSize: theme.fontSizes.xl }}>Add a new invoice</Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing.sm,
          marginTop: theme.spacing.md,
        }}
      >
        <AutoSelect
          title="Select client"
          placeholder="search for a client"
          preselected={preclient ? preclient : null}
          getData={(data) => getClients(data, true)}
          callBack={setClient}
        />
        <AutoSelect
          title="Select issuer / credentials"
          placeholderx="search for credentials by company name"
          placeholder="Ieraksti SIA un izvēlies pirmo"
          getData={(data) => getBank(data)}
          callBack={setBank}
        />
        <DateInput
          label="Due date"
          value={dueDate}
          onChange={handleDueDateChange}
          style={{ marginBottom: 10 }}
        />
      </div>
      {services.map((service, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <div style={{ flexBasis: "45%" }}>
            <Input
              placeholder="Enter service name"
              value={service.name}
              onChange={(event) => handleCustomServiceNameChange(event, index)}
              style={{ marginBottom: 5 }}
            />
          </div>
          <div style={{ flexBasis: "45%" }}>
            <Input
              placeholder="Cost"
              value={service.cost}
              type="number"
              onChange={(event) =>
                handleServiceChange(event.target.value, index, "cost")
              }
              style={{ marginBottom: 5 }}
            />
          </div>
          <Button
            variant="outline"
            color="red"
            onClick={() => handleRemoveService(index)}
            style={{ flexBasis: "10%", marginBottom: 5 }}
          >
            Remove
          </Button>
        </div>
      ))}
      <Button
        style={{ marginBottom: 5 }}
        onClick={handleAddService}
        variant="outline"
        color="gray"
        fullWidth
      >
        Add service
      </Button>
      <div style={{ marginTop: theme.spacing.md }}>
        <TextInput
          placeholder="Tax rate"
          value={taxRate}
          type="number"
          onChange={(event) => setTaxRate(event.target.value)}
          style={{ marginBottom: 5 }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          inputContainer={(children) => (
            <Tooltip
              label="PVN simtdaļās (0.21 = 21% PVN)"
              position="top-start"
              opened={focused}
            >
              {children}
            </Tooltip>
          )}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Text
            style={{
              fontWeight: 600,
              marginRight: 5,
              flexBasis: "22%",
            }}
          >
            Subtotal:
          </Text>
          <Text>${Number(subtotal).toFixed(2)}</Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
            color: "gray",
          }}
        >
          {taxRate && taxRate > 0 && (
            <>
              <Text
                style={{
                  fontWeight: 600,
                  marginRight: 5,
                  fontSize: 13,
                  flexBasis: "22%",
                }}
              >
                {taxRate * 100}% Tax:
              </Text>
              <Text style={{ color: "gray", fontSize: 13 }}>
                ${Number(amountDue - subtotal).toFixed(2)}
              </Text>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Text
            style={{
              fontWeight: 600,
              marginRight: 5,
              flexBasis: "22%",
            }}
          >
            Total due:
          </Text>
          <Text>${Number(amountDue).toFixed(2)}</Text>
        </div>
        <Textarea
          placeholder="Notes"
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          style={{ marginBottom: 5 }}
        />
        <Button
          variant="filled"
          onClick={() => submitCreateInvoice()}
          style={{ backgroundColor: "blue" }}
          fullWidth
        >
          Create invoice
        </Button>
      </div>
    </div>
  );
}

export default AddInvoiceScreen;
