import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { FormattedMessage } from 'react-intl';

const CreateUserModal = ({ open, onClose, onSubmit, user }) => {
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    surname: "",
    email: "",
    password: "",
    status: "",
    access: "",
    group_id: null,
    title: "",
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user) {
      setFormData({
        userid: user.userid,
        username: user.username,
        name: user.name,
        surname: user.surname,
        email: user.email,
        status: user.status,
        access: user.access,
        group_id: user.group_id,
        title: user.title,
      });
    } else {
      setFormData({
        username: "",
        name: "",
        surname: "",
        email: "",
        password: "",
        status: "",
        access: "",
        group_id: null,
        title: "",
      });
    }
  }, [user]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <Dialog
      fullScreen={isSmallScreen}
      maxWidth="sm"
      fullWidth={!isSmallScreen}
      PaperProps={{
        style: { height: "auto", maxHeight: "76vh", overflow: "auto" },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {user ? <><FormattedMessage id="modify" />{user.name}</> : <FormattedMessage id="create_new_user" />}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin="normal"
              label={<FormattedMessage id="username" />}
              name="username"
              value={formData.username}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              margin="normal"
              label={<FormattedMessage id="name" />}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              style={{ margin: 2, padding: 2 }}
              label={<FormattedMessage id="Surname" />}
              name="surname"
              value={formData.surname}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              style={{ margin: 2, padding: 2 }}
              label={<FormattedMessage id="E-mail" />}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </Grid>
          {!user && (
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                style={{ margin: 2, padding: 2 }}
                label={<FormattedMessage id="password" />}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          {user && (
            <Grid item xs={12} md={6}>
              <InputLabel id="user-id-status"><FormattedMessage id="status" /></InputLabel>
              <Select
                fullWidth
                labelId="user-id-status"
                style={{ margin: 2, padding: 2 }}
                name="status"
                value={formData.status || "active"}
                onChange={handleInputChange}
              >
                <MenuItem value={"disabled"}><FormattedMessage id="disabled" /></MenuItem>
                <MenuItem value={"active"}><FormattedMessage id="active" /></MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <InputLabel id="group-id-label"><FormattedMessage id="group_id" /></InputLabel>
            <Select
              fullWidth
              style={{ margin: 2, padding: 2 }}
              labelId="group-id-label"
              name="group_id"
              value={formData.group_id || 1}
              onChange={handleInputChange}
            >
              <MenuItem value={1}>Admin</MenuItem>
              <MenuItem value={10}>user</MenuItem>
              <MenuItem value={20}>SOME</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
        <FormattedMessage id="cancel" />
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {formData.userid ? <FormattedMessage id="update_user" /> : <FormattedMessage id="create_user" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserModal;
